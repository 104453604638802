const GAP = 40; // px

const initSliderTechnologies = () => {
  const sliders = document.querySelectorAll('.slider-technologies');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-technologies__container');
    const slides = container.querySelectorAll('.slider-technologies__slide');
    const prevBtn = slider.querySelector('.slider-btn--prev');
    const nextBtn = slider.querySelector('.slider-btn--next');
    const navigation = {
      prevEl: prevBtn,
      nextEl: nextBtn,
    };

    // eslint-disable-next-line no-unused-vars
    let swiper;

    const initSwiper = () => {
      // eslint-disable-next-line no-undef
      swiper = new Swiper(container, {
        slidesPerView: 'auto',
        spaceBetween: GAP,
        grabCursor: true,
        mousewheel: {
          forceToAxis: true,
        },
        threshold: 10,
        navigation: slides.length > 1 ? navigation : false,
      });
    };

    initSwiper();
  });
};

export {initSliderTechnologies};
