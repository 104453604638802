const sliders = document.querySelectorAll('.slider-product');

const initSliderProduct = () => {
  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const product = slider.closest('.product');
    const thumbs = slider.querySelector('.slider-product__thumbs');
    const photos = slider.querySelector('.slider-product__photos');
    const prevBtn = slider.querySelector('.slider-btn--prev');
    const nextBtn = slider.querySelector('.slider-btn--next');
    const thumbSlides = thumbs.querySelectorAll('.slider-product__thumbs-slide');

    const isNeedBtns = thumbSlides.length > 5 ? true : false;

    if (!isNeedBtns) {
      prevBtn.style.display = 'none';
      nextBtn.style.display = 'none';
    }

    let thumbsSwiper;
    // eslint-disable-next-line no-unused-vars
    let photosSwiper;

    const initSwipers = () => {
      // eslint-disable-next-line no-undef
      thumbsSwiper = new Swiper(thumbs, {
        rewind: true,
        spaceBetween: 10,
        slidesPerView: 5,
        grabCursor: true,
        mousewheel: {
          forceToAxis: true,
        },
        threshold: 10,
        navigation: isNeedBtns ? {
          nextEl: nextBtn,
          prevEl: prevBtn,
        } : false,
        breakpoints: {
          414: {
            spaceBetween: 20,
          },
        },
      });

      // eslint-disable-next-line no-undef
      photosSwiper = new Swiper(photos, {
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: false,
        thumbs: {
          swiper: thumbsSwiper,
        },
      });
    };

    initSwipers();

    if (!product) {
      return;
    }

    const productColorList = product.querySelector('.product__color-list');

    const onProductColorListClick = ({target}) => {
      const productColorBtn = target.closest('.product__color-btn');

      if (productColorBtn) {
        const dataColor = productColorBtn.dataset.productColorLink;

        const targetThumb = slider.querySelector(`[data-product-color-slide="${dataColor}"]`);

        if (targetThumb) {
          const slideNumber = targetThumb.ariaLabel.split(' / ')[0];

          photosSwiper.slideTo(slideNumber);
        }
      }
    };

    productColorList.addEventListener('click', onProductColorListClick);
  });
};

export {initSliderProduct};
