const ScrolledLimit = {
  DESKTOP: 220, // px
  MOBILE: 190, // px
};

const breakpoint = window.matchMedia('(min-width: 1024px)');

const header = document.querySelector('.header');

const initScrollHeader = () => {
  if (!header) {
    return;
  }

  let scrollValue = breakpoint.matches ? ScrolledLimit.DESKTOP : ScrolledLimit.MOBILE;

  const onWindowScroll = () => {
    if (window.scrollY >= scrollValue) {
      header.classList.add('is-scrolled-temp');

      setTimeout(() => {
        header.classList.add('is-scrolled');
        header.classList.remove('is-scrolled-temp');
      }, 10);
    } else {
      header.classList.remove('is-scrolled');
    }
  };

  breakpoint.addListener(() => {
    scrollValue = breakpoint.matches ? ScrolledLimit.DESKTOP : ScrolledLimit.MOBILE;
  });

  window.addEventListener('scroll', onWindowScroll);
};

export {initScrollHeader};
